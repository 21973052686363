import React, { useState } from "react"

import Layout from "layout"
import Container from "layout/Container"
import Loading from "elements/Loading"

import ScheduleView from "./ScheduleView"

const BookingSchedule = ({ pageContext }) => {
  let [view, setView] = useState("schedule")

  let { module, progress, numberOfPages } = pageContext

  const renderScheduleView = (view) => {
    switch (view) {
      case "loading":
        return (
          <div className="has-text-centered">
            <Loading size={10} />
            <p className="has-text-grey has-text-centered">
              Please wait while we process your booking.
            </p>
          </div>
        )
      case "schedule":
      default:
        return (
          <Container isCentered>
            <ScheduleView pageContext={pageContext} setView={setView} />
          </Container>
        )
    }
  }

  return (
    <Layout
      title={module?.title}
      subtitle={module?.subtitle}
      seoTitle={module?.seoTitle}
      pageContext={pageContext}
      isPrivate
      progress={(progress / numberOfPages) * 100}
      numberOfPages={numberOfPages}
    >
      {renderScheduleView(view)}
    </Layout>
  )
}

export default BookingSchedule
