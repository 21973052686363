import React, { useContext, useEffect, Fragment } from "react"
import { navigate } from "gatsby"
import { get } from "lodash"
import classNames from "classnames"

import Section from "elements/Section"

import EditDetailsButton from "elements/EditDetailsButton"
import FileThumbnail from "elements/UploadDocumentDropzone/FileThumbnail"

import Calendly from "./Calendly"

import { isBrowser } from "services/general"
import {
  automateBookingSubmission,
  automateMedstaffBookingSubmission,
} from "../services/booking"

import { ConsultationContext } from "../../ConsultationContext/ConsultationContext"
let { parseFormField } = require("services/airtable")

const ScheduleView = ({ pageContext, setView }) => {
  const { nextPath, backPath, type } = pageContext
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )

  useEffect(() => {
    if (isBrowser()) consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    //eslint-disable-next-line
  }, [consultationDispatch])

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )

  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSuccessCallback = ({ data }) => {
    if (isBrowser())
      consultationDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    navigate(nextPath, { state: data })
  }

  return (
    <Fragment>
      {pageContext.type !== "medstaff" &&
        sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: <EditDetailsButton route={backPath} />,
              }}
            >
              <div className="table-container">
                <table class="table is-fullwidth label-size-input">
                  <tbody>
                    {section.fields
                      .sort((firstField, secondField) => {
                        return firstField.order - secondField.order
                      })
                      .map((field) => {
                        let finalValue = null
                        switch (field.type) {
                          case "select":
                            finalValue = get(consultationState, field.name)
                              ? get(consultationState, field.name)?.label
                              : ""
                            break
                          case "date":
                            finalValue = get(consultationState, field.name)
                              ? `${
                                  get(consultationState, field.name)?.month
                                    ?.label
                                } ${
                                  get(consultationState, field.name)?.date
                                    ?.label
                                }, ${
                                  get(consultationState, field.name)?.year.label
                                }`
                              : ""
                            break
                          case "address":
                            finalValue = get(consultationState, field.name)
                              ? `${
                                  get(consultationState, field.name)
                                    ?.streetAddress
                                }, ${
                                  get(consultationState, field.name)?.city
                                    ?.label
                                }, ${
                                  get(consultationState, field.name)?.province
                                    ?.label
                                }`
                              : ""
                            break
                          case "upload":
                            finalValue = consultationState.documents?.map(
                              (document) => <FileThumbnail file={document} />
                            )
                            break
                          case "schedule":
                            finalValue = get(consultationState, field.name)
                              ? `${
                                  get(consultationState, field.name)?.day?.label
                                }, ${
                                  get(consultationState, field.name)?.time
                                    ?.label
                                }`
                              : ""
                            break
                          case "checkbox":
                            finalValue = consultationState[field.name].join(
                              ", "
                            )
                            break
                          default:
                            finalValue = get(consultationState, field.name)
                            break
                        }

                        if (!!finalValue)
                          return (
                            <tr>
                              <td>{field.summaryLabel}</td>
                              <td
                                className={classNames("has-text-weight-bold")}
                              >
                                {finalValue}
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}

      <Calendly
        type={type}
        state={consultationState}
        backPath={backPath}
        callback={async (uri) => {
          setView("loading")

          if (type === "medstaff") {
            await automateMedstaffBookingSubmission({
              intake: consultationState,
              event: uri,
              callback: handleSuccessCallback,
            })
          } else {
            await automateBookingSubmission({
              intake: consultationState,
              event: uri,
              callback: handleSuccessCallback,
            })
          }
        }}
      />
    </Fragment>
  )
}

export default ScheduleView
