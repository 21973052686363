import React, { Fragment, useState } from "react"

// import { navigate } from "gatsby"
import { InlineWidget, CalendlyEventListener } from "react-calendly"

// import Button from "elements/Button"
import Message from "elements/Message"

import { getSignedInUser } from "../../../Auth/services/user"

import {
  GATSBY_CALENDLY_GMEET_BOOKING_PAGE_URL,
  GATSBY_CALENDLY_PHONE_CALL_BOOKING_PAGE_URL,
  GATSBY_CALENDLY_LANDLINE_PAGE_URL,
  GATSBY_CALENDLY_VIBER_BOOKING_PAGE_URL,
  GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL,
} from "gatsby-env-variables"
// import { convertToPHInternationalFormat } from "./utils/convertToInternationalFormat"

const Calendly = ({ callback, backPath, state, type }) => {
  const { userData } = getSignedInUser()

  const [successMessage, setSuccessMessage] = useState(null)

  let prefill = {
    name:
      type === "medstaff"
        ? `${state.firstName} ${state.lastName}`
        : `${userData?.firstName} ${userData?.lastName}`,
    email: type === "medstaff" ? state.email : userData?.email,
  }

  const getCalendarUrl = () => {
    switch (state?.channel?.toLowerCase()) {
      case "mobile phone call":
        prefill = {
          ...prefill,
          customAnswers: {
            a1: state?.mobileNumber,
            a2: `${GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL}${state.recordId}`,
          },
        }
        // return (
        //   GATSBY_CALENDLY_PHONE_CALL_BOOKING_PAGE_URL +
        //   `?phone_number=${convertToPHInternationalFormat(state?.mobileNumber)}`
        // )

        return GATSBY_CALENDLY_PHONE_CALL_BOOKING_PAGE_URL
      case "landline phone call":
        prefill = {
          ...prefill,
          customAnswers: {
            a1: state?.landlineNumber,
            a2: `${GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL}${state.recordId}`,
          },
        }
        return GATSBY_CALENDLY_LANDLINE_PAGE_URL
      case "viber":
        prefill = {
          ...prefill,
          customAnswers: {
            a1: state?.viberNumber,
            a2: `${GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL}${state.recordId}`,
          },
        }
        return GATSBY_CALENDLY_VIBER_BOOKING_PAGE_URL
      case "google meet":
      default:
        prefill = {
          ...prefill,
          customAnswers: {
            a1: `${GATSBY_AIRTABLE_TELECONSULT_OPS_INTERFACE_BASE_URL}${state.recordid}`,
          },
        }
        return GATSBY_CALENDLY_GMEET_BOOKING_PAGE_URL
    }
  }

  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
    primaryColor: "0d6d6e",
  }

  const handleScheduledEvent = async (e) => {
    setSuccessMessage(
      <p className="">
        Your teleconsult has been scheduled. Please wait as we redirect you to
        another page for further instructions.
      </p>
    )

    if (callback) callback(e?.data?.payload?.event?.uri)
  }

  return (
    <Fragment>
      {successMessage ? <Message color="info">{successMessage}</Message> : null}
      <InlineWidget
        url={getCalendarUrl()}
        prefill={prefill}
        pageSettings={pageSettings}
        styles={{ height: "700px" }}
      />
      <CalendlyEventListener onEventScheduled={handleScheduledEvent} />
      {/* <Button
        isFullwidth
        onClick={() => {
          navigate(backPath)
        }}
      >
        Back to Intake Form
      </Button> */}
    </Fragment>
  )
}

export default Calendly
